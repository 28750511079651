import { makeStyles, Theme } from '@material-ui/core';

export const FOOTER_MIN_HEIGHT = 64;

export const useFooterStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.common.white,
    color: '#000',
    paddingTop: '48px',
    paddingBottom: '48px',
    [theme.breakpoints.up('md')]: {
      minHeight: FOOTER_MIN_HEIGHT,
    },
  },

  container: {

  },
  copyright: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: '48px',
    '& img': {
      height: '60px',
    }
  },
  list: {
    display: 'flex',
    width: '600px',
    justifyContent: 'space-between',
    '& ul': {
      padding: '0'
    },
    '& li': {
      listStyle: 'none',
      marginTop: '16px',
      color: '#8787A2',
      fontSize: '14px',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:first-child': {
        marginTop: '0',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'auto',
        color: '#fff'
      },
    }
  },
  join: {
    display: 'flex'
  },
  iconSvg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    width: '48px',
    height: '48px',
    marginRight: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
    '& img': {
      display: 'block'
    }
  },
  terms: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
    fontSize: '13px',
    fontWeight: 400,
    '& p': {
      margin: '0 14px',
      letterSpacing: '1.04px',
    }
  },
  copy: {
    margin: '26px 14px 0',
    letterSpacing: '1.04px',
    textAlign: 'center',
  }
}));
