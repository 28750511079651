import { Container } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import { t } from 'modules/i18n/utils/intl';
import { Button } from 'modules/uiKit/Button';
import { Wallet } from '../Wallet';
import { useHeaderStyles } from './HeaderStyles';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
// import { NavLink } from 'react-router-dom';
import { useHeaderLinksStyles } from '../HeaderLinks/HeaderLinksStyles';
import {
  SelectChainDialog,
  useDialogState,
} from 'modules/layout/components/Header/components/SelectChainDialog';
import { LocaleSwitcher } from 'modules/common/components/LocaleSwitcher';
import { Social } from 'modules/common/components/Social';
import { IndexRoutesConfig } from 'modules/minarioHome/Routes';
import { useQuery } from '@redux-requests/react';
import { getDfdunkSns, IDfdunkSnsData } from 'modules/common/actions/commonRequest';
import { getDfdunkLogo, IDfdunkLogoData, getDfdunkTab, IDfdunkTabDate } from 'modules/common/actions/commonRequest';
import { Link } from 'react-router-dom';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { HeaderNavationDrawer } from './components/HeaderNavationDrawer';
import { useHistory } from 'react-router-dom';

const lindSpaceStyles = { marginLeft: 8 };

export const Header = () => {
  const { isConnected, handleConnect, loading,
    chainId
  } = useAccount();
  const classes = useHeaderStyles();
  const linksClasses = useHeaderLinksStyles();
  const renderedWallet = <Wallet />;
  const {
    opened: openedSelectChainDialog,
    close: closeSelectChainDialog,
  } = useDialogState();
  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  const { data: tabData } = useQuery<IDfdunkTabDate[] | null>({
    type: getDfdunkTab.toString(),
  });
  const { data: snsData } = useQuery<IDfdunkSnsData | null>({
    type: getDfdunkSns.toString(),
  });

  const { locale } = useLocale();
  const history = useHistory();
  const renderedDesktop = (
    <>
      {!isConnected && (
        <Button onClick={handleConnect} className={classNames(classes.connect, classes.connectBorder)} loading={loading}>
          {t('header.connect')}
        </Button>
      )}
      {isConnected && (
        <div>
          {renderedWallet}
        </div>
      )}
    </>
  );


  return (
    <header className={classNames(classes.root)}>
      <Container className={classes.container} maxWidth={false}>
        <SelectChainDialog
          isOpen={openedSelectChainDialog}
          onClose={closeSelectChainDialog}
          currentChain={chainId}
        />
        <Box className={classes.navs}>
          <Link
            to={IndexRoutesConfig.Index.path}
            className={classes.root}
          >
            <img
              alt="DFDunk"
              className={classNames(classes.img, classes.ableDark)}
              src={logoData ? logoData.logo : ''}
            />
          </Link>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          {tabData?.map((item) => {
            return item.status && <Button
              // component={NavLink}
              variant="text"
              style={lindSpaceStyles}
              activeClassName={linksClasses.activeLink}
              className={linksClasses.link}
              to={item.link}
              key={item.id}
              onClick={() => {
                if (item.link === '/actress/coming_soon') {
                  window.location.href = 'https://hotluuu-idol.io/collection'
                  // onNavClose({ event: {} });
                } else {
                  history.push(item.link);
                  // onNavClose({ event: {} });
                }
              }}
            >
              {locale === 'zh-CN' ? item.nameZh : locale === 'ja-JP' ? item.nameJp : item.nameEn}
            </Button>
          })}
          <Box className={classes.switcher}>
            <LocaleSwitcher />
          </Box>
          <Box className={classes.switcher}>
            <Social
              twitter={snsData?.twitter}
              instagram={snsData?.instagram}
              telegram={snsData?.telegram}
              discord={snsData?.discord}
              facebook={snsData?.facebook}
            />
          </Box>
          {renderedDesktop}
          <HeaderNavationDrawer navData={tabData} snsData={snsData} />
        </Box>

      </Container>
    </header>
  );
};
