import React, { useState } from 'react';
import { styles } from './useHeaderNavationDrawerStyles';
import { withStyles } from '@material-ui/core';
import DrawerImg from 'modules/layout/components/Header/assets/darwer.png';
import { SwipeableDrawer } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { DraweCloseComp } from '../DrawerClose';
import { IDfdunkSnsData, IDfdunkTabDate } from 'modules/common/actions/commonRequest';
import { Button } from 'modules/uiKit/Button';
// import { NavLink } from 'react-router-dom';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { Social } from '../../../Footer/components/Social';
import { useHistory } from 'react-router-dom';

type Anchor = 'right';
const lindSpaceStyles = {};
const HeaderDrawerNavComponents = (
  {
    navData,
    classes = {},
    snsData,
  }: { classes: any, navData: IDfdunkTabDate[] | null, snsData: IDfdunkSnsData | null }) => {

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useLocale();
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };
  return (
    <>
      <Box
        className={classes.root}
        onClick={toggleDrawer('right', true)}
        onKeyDown={toggleDrawer('right', false)}
      >
        <img src={DrawerImg} alt='' className={classes.userIcon} />
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        classes={{
          paper: classes.paper,
        }}
      >
        <Box className={classes.drawerBody}>
          <DraweCloseComp toggleDrawer={toggleDrawer('right', false)} />
          <Box className={classes.drawerContent}>
            <Box className={classes.linkWrap}>
              {
                navData?.map((d: IDfdunkTabDate) => {
                  return (
                    d.status ? <Button
                      key={d.link}
                      // component={NavLink}
                      variant='text'
                      style={lindSpaceStyles}
                      activeClassName={classes.activeLink}
                      className={classes.link}
                      to={d.link}
                      onClick={() => {
                        if (d.link === '/actress/coming_soon') {
                          window.location.href = 'https://hotluuu-idol.io/collection'
                          setIsOpen(false);
                        } else {
                          history.push(d.link);
                          setIsOpen(false);
                        }
                      }}
                    >
                      {locale === 'en-US' ? d.nameEn : locale === 'zh-CN' ? d.nameZh : locale === 'ja-JP' ? d.nameJp : ''}
                    </Button> : null
                  );
                })
              }
            </Box>


            <Social
              className={'drawerLink'}
              twitter={snsData?.twitter}
              instagram={snsData?.instagram}
              telegram={snsData?.telegram}
              discord={snsData?.discord}
              facebook={snsData?.facebook}
            />
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

const HeaderNavationDrawer = withStyles(styles)(HeaderDrawerNavComponents);
export { HeaderNavationDrawer };
