import { Theme } from '@material-ui/core';
import { StyleRules } from "@material-ui/styles";

export const styles = (theme: Theme): StyleRules => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    minWidth: 60,
    maxWidth: 80,
    height: 28,
    color: '#fff',
    // background: 'rgba(255, 255, 255, 0.1)',
    // marginLeft: '8px',
    borderRadius: '4px',
    '& svg': {
      width: '20px',
      height: "10px",
      color: '#fff',
      top: 'calc(50% - 5px)',
      right: '8px',
    },
  },
  slectedStyle: {
    padding: '8px 24px 8px 14px !important',
  }
});
