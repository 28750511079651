import React, { ChangeEvent, useCallback } from 'react';
import { Locale } from 'modules/i18n/types/locale';
import { setLocale } from 'modules/i18n/i18nSlice';
import { useLocaleMemo } from 'modules/i18n/utils/useLocaleMemo';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { styles } from './LocaleSwitcherStyles';
import { Select } from 'modules/uiKit/Select';
import { t } from 'modules/i18n/utils/intl';
import { withStyles } from "@material-ui/core";

import { useAppDispatch } from 'store/useAppDispatch';

const LocaleSwitcherComponent = (
  { classes = {} }: { classes: any }
) => {
  const dispatch = useAppDispatch();
  const localeOptions = useLocaleMemo(
    () => [
      {
        value: Locale.en,
        label: t('language.en-US'),
      },
      {
        value: Locale.ja,
        label: t('language.ja-JP'),
      },
      {
        value: Locale.zh,
        label: t('language.zh-CN'),
      }
    ],
    [],
  );

  const { locale } = useLocale();

  const onChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      dispatch(setLocale(event.target.value as Locale));
    },
    [dispatch],
  );

  return (
    <Select
      className={classes.root}
      value={locale}
      onChange={onChange}
      options={localeOptions}
      fullWidth={false}
      classes={{ select: classes.slectedStyle }}
    />
  );
};

const LocaleSwitcher = withStyles(styles)(LocaleSwitcherComponent);

export { LocaleSwitcher };
