import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import logo01 from './assets/logo02.png';
// import logo from './assets/logo.png';
import logo from './assets/logo.svg';
import { useLogoStyles } from './LogoStyles';
import { IndexRoutesConfig } from 'modules/minarioHome/Routes';

export const Logo = () => {
  const classes = useLogoStyles();
  return (
    <Link
      to={IndexRoutesConfig.Index.path}
      className={classes.root}
    >
      <img
        alt="DFDunk"
        className={classNames(classes.img, classes.ableDark)}
        src={logo}
      />
      <img
        alt="DFDunk"
        className={classNames(classes.img, classes.ableLight)}
        src={logo}
      />
    </Link>
  );
};
